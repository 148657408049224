<template>
  <click-to-edit
    v-model="scope.row.scale.target_achieve_level"
    placeholder=""
    :disabled="!isFieldAvailableForEdit(scope.row, 'scale.target_achieve_level')"
    type="select"
    select-filterable
    :select-options="[
        {id: 1, name:'Не выполнено'},
        {id: 2, name:'Частично выполнено'},
        {id: 3, name:'Выполнено'},
        {id: 4, name:'Перевыполнено'},
      ]"
    select-option-id="id"
    select-option-name="name"
    select-cast-value-to-int
    @input="saveElementPropChange(scope.row, 'scale', {target_achieve_level:$event})"
  >
  </click-to-edit>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-period-result-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  computed: {},
  data() {
    return {
    }
  },
  methods: {}
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

</style>
